<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="12"
        lg="3"
      >
        <b-card>
          <div class="d-flex h-100 align-items-center justify-content-center">
            <div class="">
              <div class="d-flex flex-column text-center">
                <router-link
                  :to="{
                    name: 'expensesByGroup',
                    params: {
                      projectId: $route.params.projectId,
                      groupedExpenseId: supply.id,
                    },
                  }"
                  class="text-primary h2 font-weight-bolder"
                >
                  {{ supply.name }}
                </router-link>
                <!-- <h2>{{ supply.name }}</h2> -->
                <div class="d-flex flex-column">
                  <span class="text-muted h4">{{ supply.sku }}</span>
                </div>
              </div>
              <!-- <div>
              <b-img
                fluid
                width="70px"
                :src="supply.image"
              />
            </div> -->
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="12"
        lg="8"
      >
        <supply-progress :supply="supply" />
      </b-col>
      <b-col
        md="12"
        lg="1"
        class="mb-2 mb-md-0"
      >
        <div class="d-flex flex-column h-100 justify-content-center">
          <b-button
            size="sm"
            class="mb-1"
            variant="primary"
            @click="openEditModal()"
          >
            {{ $t('edit') }}
          </b-button>
          <b-button
            v-b-modal="`confirmDelete-${supply.id}`"
            size="sm"
            variant="outline-danger"
          >
            {{ $t('delete') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <confirmation-modal
      :id="`confirmDelete-${supply.id}`"
      :variant="'danger'"
      @confirm="deleteExpense()"
    >
      <template v-slot:content>
        {{ $t('deleteSupplyConfirmation') }}
      </template>
    </confirmation-modal>
    <b-modal
      :id="`edit-${supply.id}`"
      @ok="editExpense"
    >
      <b-row>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topQuantity')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="expenseToEdit.top_quantity"
              type="number"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topAmount')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="expenseToEdit.top_amount"
              type="number"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
// import SupplyTable from '@/components/supplies/SupplyTable.vue'
import SupplyProgress from '@/components/supplies/SupplyProgress.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default {
  components: {
    SupplyProgress,
    // SupplyTable,
    ConfirmationModal,
  },
  props: {
    supply: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      expenseToEdit: {},
    }
  },
  methods: {
    ...mapActions('groupedExpenses', [
      'deleteGropedExpense',
      'fetchGroupedExpenses',
      'editGroupedExpense',
    ]),
    deleteExpense() {
      this.deleteGropedExpense(this.supply.id).then(() => {
        this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('dataSaved'),
            icon: 'MinimizeIcon',
            variant: 'success',
          },
        })
      })
    },
    openEditModal() {
      this.$bvModal.show(`edit-${this.supply.id}`)
      this.expenseToEdit = JSON.parse(JSON.stringify(this.supply))
    },
    editExpense() {
      this.editGroupedExpense(this.expenseToEdit).then(() => {
        this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('dataSaved'),
            icon: 'MinimizeIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide(`edit-${this.supply.id}`)
      })
    },
  },
}
</script>

<style></style>
